/* global pendo */

const { ref } = VueCompositionAPI

import useApi from "services/useApi"

const pendoKey = process.env.PENDO_KEY
const dataURL = "content.data.product.buttercms.com"
const accountAnalytics = ref( null )
const timestampAnalytics = ref( null )

const analyticsRoutes = {
    GET_ANALYTICS: "/analytics/retrieve-analytics-properties/",
    GET_TIMESTAMP: "/analytics/retrieve-analytics-timestamp/"
}

/**
 * Hook for loading and initializing analytics tracking
 *
 * This hook handles loading analytics data and initializing Pendo tracking.
 * It manages caching analytics data in sessionStorage and mounting the Pendo
 * tracking script with visitor/account data.
 *
 * If no Pendo API key is configured,
 * returns a mock implementation that does nothing.
 *
 * @returns {Object} Analytics loader methods
 * @returns {Function} loadAnalyticsDataset - Loads and caches analytics data
 */

export default function useAnalyticsLoader () {
    /**
     * Early return if no Pendo API key is configured
     * Returns a mock analytics loader that does nothing to prevent errors
     * when analytics tracking is disabled or not configured
     *
     * @returns {Object} Mock analytics object with empty methods
     */

    if ( !pendoKey ) {
        return {
            loadAnalyticsDataset: () => ({})
        }
    }

    /**
     * Loads and initializes analytics data for the current user session
     *
     * @description
     * Manages analytics data loading and caching flow:
     * 1. Checks sessionStorage for cached "butter-analytics" data
     * 2. If valid cache exists, uses cached data
     * 3. If cache missing/invalid, fetches fresh data from API
     * 4. Caches the fresh data in sessionStorage
     * 5. Initializes Pendo tracking with the analytics data
     *
     * @typedef {Object} AnalyticsData
     * @property {Object} account_properties - Account/org tracking properties
     * @property {string} account_properties.company_name - Organization name
     * @property {string} account_properties.environment - Which environment user is accessing
     * @property {string[]} account_properties.feature_flags - List of feature flags for an account
     * @property {boolean} account_properties.has_multisite - Whether organization has multisites activated
     * @property {string} account_properties.multisite_id - ID of current multisite
     * @property {number} account_properties.organization_id - Org identifier
     * @property {string} account_properties.plan_id - Subscription plan ID
     * @property {string} account_properties.plan_type - Level of plan with Butter
     * @property {string} account_properties.site_name - Name of current site user is accessing
     * @property {Date} account_properties.trial_expiry_date - When Trial expires
     * @property {Object} visitor_properties - Visitor tracking properties
     * @property {string} visitor_properties.current_project_type_questionnaire - Answer to project type in questionnaire
     * @property {string} visitor_properties.email_domain - User's email domain
     * @property {boolean} visitor_properties.is_multisite_user - Whether user is able to have multisites
     * @property {string} visitor_properties.position_questionnaire - Answer to position type in questionnaire
     * @property {string} visitor_properties.signup_method - How the user signed up for butter
     * @property {string} visitor_properties.site_type_to_build_questionnaire - Answer to site type in questionnaire
     * @property {number} visitor_properties.user_id - Unique user identifier
     * @property {number} visitor_properties.visitor_id - Visitor session ID
     *
     * @async
     * @returns {Promise<{analytics: import('vue').Ref<AnalyticsData>, timestamp: import('vue').Ref<string>}>} Object containing refs for analytics data and timestamp
     * @throws {Error} If analytics data fetch fails
     */

    async function loadAnalyticsDataset () {
        /**
         * First, fetch the current analytics timestamp from the API
         * This timestamp is used to determine if cached analytics data is still valid
         * The timestamp acts as a version identifier for the analytics data
         */

        timestampAnalytics.value = await useApi.fetch({
            route: analyticsRoutes.GET_TIMESTAMP
        })

        /**
         * Retrieve any previously cached analytics data from sessionStorage
         * This helps avoid unnecessary API calls on page refreshes or navigation
         * @type {string|null} Raw JSON string from sessionStorage or null if not found
         */

        const storedAnalytics = sessionStorage.getItem( "butter-analytics" )

        try {
        /**
             * Attempt to parse the stored analytics data
             * Use default values of null if parsing fails or values don't exist
             * This destructuring with defaults handles both parsing errors and missing data
             * @type {Object} Parsed analytics data with fallback to empty values
             */

            const {
                analytics = null,
                timestamp = null
            } = storedAnalytics
                ? JSON.parse( storedAnalytics )
                : {}

            /**
             * Set the analytics ref to the cached value (if it exists)
             * This makes the data immediately available to components
             */

            accountAnalytics.value = analytics

            /**
             * Determine if we need to refresh the analytics data based on two conditions:
             *
             * 1. If the timestamp from the API doesn't match our cached timestamp
             * This indicates the backend data has been updated since our last fetch
             * @type {boolean} True if timestamps don't match
             */

            const needsRefreshBasedOnTimestamp = timestampAnalytics.value !== timestamp

            /**
             * 2. If we don't have cached analytics data or timestamp
             * This indicates first visit or cleared cache
             * @type {boolean} True if either analytics or timestamp is missing
             */

            const needsRefreshBasedOnSession = !analytics || !timestamp

            /**
             * If either condition is true, throw an error to trigger the catch block
             * This is an intentional flow control mechanism to refresh the data
             * Using throw/catch pattern to avoid duplicating refresh logic
             */

            if ( needsRefreshBasedOnSession || needsRefreshBasedOnTimestamp ) throw "Collect fresh analytics initialization"
        }
        catch ( err ) {
            /**
             * Log the reason for refreshing analytics data
             * This could be due to invalid cache, missing cache, or timestamp mismatch
             * Useful for debugging cache behavior
             */

            console.info( err )

            /**
             * Fetch fresh analytics data from the API
             * This gets the latest visitor and account properties for tracking
             */

            accountAnalytics.value = await useApi.fetch({
                route: analyticsRoutes.GET_ANALYTICS
            })

            /**
                 * Cache the fresh analytics data and current timestamp in sessionStorage
                 * This prevents unnecessary API calls on subsequent page loads
                 */

            sessionStorage.setItem(
                "butter-analytics",
                JSON.stringify({
                    analytics: accountAnalytics.value,
                    timestamp: timestampAnalytics.value,
                })
            )
        }

        /**
         * Initialize Pendo analytics tracking with the loaded data
         * This injects the Pendo script and configures it with our visitor/account data
         * Called regardless of whether we used cached or fresh data
         */

        mountPendo()

        /**
         * Return both the analytics data and timestamp as Vue refs
         * This allows components to reactively access this data
         * @type {Object} Object containing reactive refs to analytics data and timestamp
         */

        return {
            analytics: accountAnalytics,
            timestamp: timestampAnalytics
        }
    }


    /**
     * Mounts the Pendo analytics tracking script into the page
     *
     * This function injects the Pendo JavaScript snippet that loads
     * their analytics tracking agent. It uses an IIFE pattern to:
     * 1. Create a queue for tracking calls
     * 2. Set up core tracking methods (initialize, identify, etc)
     * 3. Asynchronously load the Pendo script with the provided API key
     * 4. Call initializePendo() to set up visitor/account tracking
     *
     * @private
     * @see initializePendo
     */

    function mountPendo () {
        /* eslint-disable */
        ( function ( apiKey ) {
            /**
             * Standard Pendo initialization snippet
             * This is the recommended code from Pendo's documentation
             * Creates a queue for tracking calls before the script loads
             * 
             * @param {Window} p - Window object
             * @param {Document} e - Document object
             * @param {string} n - Script tag name
             * @param {string} d - Global object name for Pendo
             * @param {Object} o - Pendo object reference
             */
            ( function ( p, e, n, d, o ) {
                let v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || []
                /**
                 * List of core Pendo methods to create
                 * These methods will queue calls until the script loads
                 */
                v = [
                    "initialize", "identify", "updateOptions", "pageLoad", "track"
                ]

                /**
                 * Create each method on the Pendo object
                 * Each method queues the call until the actual script loads
                 */
                for ( w = 0, x = v.length; w < x; ++w ) {
                    ( function ( m ) {
                        o[m] = o[m] || function () { o._q[m === v[0] ? "unshift" : "push"]([ m ].concat([].slice.call( arguments, 0 ) ) ) }
                    })( v[w])
                }
                
                /**
                 * Create and inject the Pendo script tag
                 * Loads the Pendo agent asynchronously
                 */
                y = e.createElement( n ); y.async = !0; y.src = `https://${ dataURL }/agent/static/${ apiKey }/pendo.js`
                z = e.getElementsByTagName( n )[0]; z.parentNode.insertBefore( y, z )
            })( window, document, "script", "pendo" )

            /**
             * Initialize Pendo with our visitor and account data
             * Called after the script tag is injected
             */
            initializePendo()
        })( pendoKey )
        /* eslint-enable */
    }

    /**
     * Initializes Pendo analytics with visitor and account data
     *
     * This function takes the analytics data stored in the analytics ref object
     * and formats it for Pendo tracking. It transforms snake_case backend properties
     * into camelCase properties that Pendo expects.
     *
     * @private
     * @see mountPendo
     * @see {@link https://support.pendo.io/hc/en-us/articles/360031832072-Configure-visitor-and-account-metadata|Pendo Metadata Configuration}
     */

    function initializePendo () {
        /**
         * Extract the account and visitor properties from our Vue ref
         * These properties come from the backend API and use snake_case naming
         * We need to transform them to camelCase for Pendo's expected format
         */

        const {
            account_properties: account,
            visitor_properties: visitor
        } = accountAnalytics.value

        /**
         * Create the visitor object for Pendo tracking
         * This identifies the current user and their properties
         *
         * @type {Object} pendoVisitor
         * @property {string} currentProjectTypeQuestionnaire - User's response about their project type from onboarding
         * @property {string} emailDomain - Domain portion of user's email (used for company identification)
         * @property {number|string} id - Unique visitor tracking identifier (may be different from userId for multisite users)
         * @property {boolean} isMultisiteUser - Whether user has access to multiple sites
         * @property {string} positionQuestionnaire - User's role/position from onboarding questionnaire
         * @property {string} signupMethod - How the user registered (email, Google, etc.)
         * @property {string} siteTypeToBuildQuestionnaire - User's response about site type from onboarding
         * @property {number} userId - Internal user identifier
         */

        const pendoVisitor = {
            currentProjectTypeQuestionnaire: visitor.current_project_type_questionnaire,
            emailDomain: visitor.email_domain,
            id: visitor.visitor_id,
            isMultisiteUser: visitor.is_multisite_user,
            positionQuestionnaire: visitor.position_questionnaire,
            signupMethod: visitor.signup_method,
            siteTypeToBuildQuestionnaire: visitor.site_type_to_build_questionnaire,
            userId: visitor.user_id
        }

        /**
         * Create the account object for Pendo tracking
         * This identifies the organization/account and its properties
         * Used for account-level analytics and segmentation
         *
         * @type {Object} pendoAccount
         * @property {string} companyName - Organization name
         * @property {string} environment - Which environment user is accessing (production, staging, etc.)
         * @property {string[]} featureFlags - List of enabled feature flags for this account
         * @property {boolean} hasMultisite - Whether organization has multisite feature enabled
         * @property {number|string} id - Unique organization identifier
         * @property {string} multisiteId - ID of current multisite being accessed (if applicable)
         * @property {string} planId - Subscription plan identifier
         * @property {string} planType - Type of plan (free, pro, enterprise, etc.)
         * @property {string} siteName - Name of the current site being accessed
         * @property {string} trialExpiryDate - ISO date when trial expires (if applicable)
         */

        const pendoAccount = {
            companyName: account.company_name,
            environment: account.environment,
            featureFlags: account.feature_flags,
            hasMultisite: account.has_multisite,
            id: account.organization_id,
            multisiteId: account.multisite_id,
            planId: account.plan_id,
            planType: account.plan_type,
            siteName: account.site_name,
            trialExpiryDate: account.trial_expiry_date,
        }

        /**
         * Initialize Pendo with our formatted visitor and account data
         * This connects the current user session with their account information
         * enabling proper tracking and segmentation in the Pendo dashboard
         */

        pendo.initialize({
            account: pendoAccount,
            visitor: pendoVisitor,
        })
    }

    /**
     * Return the public API of this hook
     * Only exposes the loadAnalyticsDataset method
     * All other functions are private to this hook
     */

    return {
        loadAnalyticsDataset
    }
}
